var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-list",
    { attrs: { bullet: _vm.bullet } },
    _vm._l(_vm.filteredTaxonomies, function(taxonomy) {
      return _c(
        "li",
        { key: taxonomy.id },
        [
          _vm._v("\n    " + _vm._s(taxonomy.name) + "\n    "),
          _vm.edit.length > 0 && _vm.auth.isGlobalAdmin
            ? _c(
                "span",
                [
                  _c(
                    "gov-link",
                    {
                      attrs: {
                        to: {
                          name: _vm.edit,
                          params: { taxonomy: taxonomy.id }
                        }
                      }
                    },
                    [_vm._v("\n        Edit ")]
                  ),
                  _vm._v(" \n      "),
                  taxonomy.order > 1
                    ? _c(
                        "gov-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("move-up", taxonomy)
                            }
                          }
                        },
                        [_vm._v("(Move up)")]
                      )
                    : _vm._e(),
                  _vm._v(" \n      "),
                  taxonomy.order < _vm.taxonomies.length
                    ? _c(
                        "gov-link",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("move-down", taxonomy)
                            }
                          }
                        },
                        [_vm._v("(Move down)")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.taxonomyCollections[taxonomy.id]
            ? _c("gov-hint", { attrs: { for: taxonomy.id } }, [
                _vm._v(
                  "Found in " +
                    _vm._s(_vm.taxonomyCollections[taxonomy.id].join(", "))
                )
              ])
            : _vm._e(),
          taxonomy.children.length
            ? _c("ck-taxonomy-list", {
                key: taxonomy.children[0].id,
                attrs: {
                  taxonomies: taxonomy.children,
                  filteredTaxonomyIds: _vm.filteredTaxonomyIds,
                  taxonomyCollections: _vm.taxonomyCollections,
                  checkbox: false,
                  edit: _vm.edit,
                  bullet: _vm.bullet
                },
                on: {
                  moveUp: function($event) {
                    return _vm.$emit("move-up", $event)
                  },
                  moveDown: function($event) {
                    return _vm.$emit("move-down", $event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }