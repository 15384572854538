var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Taxonomies")]),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              !_vm.loading
                ? _c("ck-taxonomy-list", {
                    attrs: {
                      taxonomies: _vm.taxonomies,
                      filteredTaxonomyIds: _vm.serviceTaxonomyIds,
                      taxonomyCollections: _vm.taxonomyCollections,
                      bullet: true
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }